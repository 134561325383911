import { A } from "@solidjs/router";
import { Match, Show, Switch } from "solid-js";
import { defaultCoin, placeholderCard } from "~/assets/assets";
import { Mode } from "~/types";
import HubbleImage from "~/widgets/hubble_image";

type BrandCardProps = {
  imageUrl: string;
  name: string;
  categoryName?: string;
  discountPercentage?: number;
  brandKey: string;
  isDark?: boolean;
  class?: string;
  showHubbleCoinReward?: boolean;
  mode?: Mode;
};

export function BrandCard(props: BrandCardProps) {
  return (
    <A href={"/buy-gift-card/" + props.brandKey}>
      <div
        class={`group flex cursor-pointer flex-col gap-2  ${props.class ?? "w-[106px] lg:w-[160px]"}`}
      >
        <HubbleImage
          src={props.imageUrl}
          class="inline-block aspect-[53/59] h-fit w-full  rounded-2xl"
          alt={props.name + " logo image"}
          errorImage={placeholderCard}
        />
        <div class="flex w-full flex-col px-1">
          <div class="flex flex-col gap-[0.5]">
            <h5
              class={`line-clamp-2 text-bold ${props.isDark ? "text-baseTertiaryLight" : "text-textDark"}`}
            >
              {props.name}
            </h5>
            <div class="flex flex-row items-end ">
              <div class="flex grow flex-col items-start justify-start">
                <span class="grow overflow-hidden text-ellipsis text-nowrap text-medium text-textNormal">
                  {props.categoryName}
                </span>
                <Switch
                  fallback={
                    <Show when={props.discountPercentage}>
                      <div class="flex grow items-baseline text-successDark">
                        <span class="block text-h4">
                          {props.discountPercentage}
                        </span>
                        <span class="block text-bolder uppercase">% off</span>
                      </div>
                    </Show>
                  }
                >
                  <Match when={props.showHubbleCoinReward}>
                    <div class="flex items-center gap-1">
                      <img class="h-5 w-5" src={defaultCoin} alt="coin" />
                      <span class="text-h4 text-coinPercentageOrange">
                        {props.discountPercentage}
                        <span class="self-end text-bolder uppercase">%</span>
                      </span>
                    </div>
                  </Match>
                  <Match
                    when={
                      props.discountPercentage === 0 && props.mode === "hubble"
                    }
                  >
                    <div class="text-bolder uppercase text-textNormal">Buy</div>
                  </Match>
                </Switch>
              </div>
              <div class="pointer-events-none hidden translate-y-[-4px] items-center pr-1 opacity-0  duration-200 group-hover:opacity-100 sm:flex ">
                <i
                  class={"ph-bold ph-arrow-right text-[20px]"}
                  classList={{
                    "text-baseDark ": !props.isDark,
                    "text-baseTertiaryLight ": props.isDark,
                  }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </A>
  );
}
